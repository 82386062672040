import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const FirstContainer = styled(Container)`
    flex-direction: column;

    @media (max-width: 768px) {
        margin-bottom: 30px;
    }
`;

const BigContact = styled.span`
    font-size: 1.12em;
    letter-spacing: 0.2rem;
    font-weight: bold;
    margin: 20px 30px;

    @media (max-width: 768px) {
        margin: 10px 15px;
    }
`;

export default props => {
    // function encode(data) {
    //     return Object.keys(data)
    //         .map(
    //             key =>
    //                 encodeURIComponent(key) +
    //                 '=' +
    //                 encodeURIComponent(data[key])
    //         )
    //         .join('&');
    // }

    // const handleSubmit = e => {
    //     e.preventDefault();
    //     const form = e.target;
    //     fetch('/', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         body: encode({
    //             'form-name': form.getAttribute('name'),
    //             ...state
    //         })
    //     })
    //         .then(() => navigate(form.getAttribute('action')))
    //         .catch(error => alert(error));
    // };

    return (
        <Layout location={props.location}>
            <h1 className="major">Contact</h1>

            <Container>
                <FirstContainer>
                    <BigContact>
                        <a href="tel:+33610137992">(+33)6 10 13 79 92</a>
                    </BigContact>
                    <BigContact>
                        <a href="mailto:pannetierguillaume@gmail.com">
                            pannetierguillaume@gmail.com
                        </a>
                    </BigContact>
                </FirstContainer>

                <form
                    method="post"
                    action="/thanks/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    name="contact"
                >
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="field half first">
                        <label htmlFor="name">Nom</label>
                        <input type="text" name="name" id="name" />
                    </div>
                    <div className="field half">
                        <label htmlFor="email">Email</label>
                        <input type="text" name="email" id="email" />
                    </div>
                    <div className="field">
                        <label htmlFor="message">Message</label>
                        <textarea name="message" id="message" rows="4" />
                    </div>
                    {/* <div className="field" data-netlify-recaptcha={true} /> */}
                    <ul className="actions">
                        <li>
                            <input
                                type="submit"
                                value="Envoyer"
                                className="special"
                            />
                        </li>
                        <li>
                            <input type="reset" value="Reset" />
                        </li>
                    </ul>
                </form>
            </Container>
        </Layout>
    );
};
